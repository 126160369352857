export function highlightFolders(data: any) {
  if (data.data.outdated && data.data.isNew && data.data.folder) {
    return { background: '#ffff00' };
  }
  if (data.data.isNew && !data.data.outdated && data.data.folder) {
    return { background: '#33cc33' };
  }
  if (!data.data.isNew && data.data.outdated && data.data.folder) {
    return { background: '#ff9900' };
  }
  if (data.data.outdated) {
    return { background: '#ff9900' };
  }
  if (data.data.isNew) {
    return { background: '#33cc33' };
  }
  if (data.data.folder || data.data.nodes) {
    if (data.props.selected) return { background: '#b0aeae' };
    return { background: '#d1d1d1' };
  }
}
