import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '../../config';
//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import useResponsive from '../../hooks/useResponsive';

export default function DashboardLayout() {
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  return (
    <Box style={{ height: '100%', width: '100%', display: 'flex' }}>
      <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />
      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <div
        style={{
          flex: 1,
          paddingLeft: 4,
          paddingRight: 4,
          paddingTop: isDesktop ? HEADER.DASHBOARD_DESKTOP_HEIGHT + 2 : HEADER.MOBILE_HEIGHT + 2,
          paddingBottom: 4,
          marginLeft: collapseClick && isDesktop ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : 0,
        }}
      >
        <Outlet />
      </div>
    </Box>
  );
}
