import { ReactNode, createContext, useState, useEffect } from 'react';
// hooks
import useResponsive from '../hooks/useResponsive';

// ----------------------------------------------------------------------

type CollapseDrawerContextProps = {
  isCollapse?: boolean;
  collapseClick: boolean;
  collapseHover: boolean;
  hoverProgress: number;
  onToggleCollapse: VoidFunction;
  onHoverEnter: VoidFunction;
  onHoverLeave: VoidFunction;
};

const initialState: CollapseDrawerContextProps = {
  collapseClick: false,
  collapseHover: false,
  hoverProgress: 100,
  onToggleCollapse: () => {},
  onHoverEnter: () => {},
  onHoverLeave: () => {},
};

const CollapseDrawerContext = createContext(initialState);

type CollapseDrawerProviderProps = {
  children: ReactNode;
};

function CollapseDrawerProvider({ children }: CollapseDrawerProviderProps) {
  const isDesktop = useResponsive('up', 'lg');
  const [repeater, setRepeater] = useState<NodeJS.Timeout>();
  const [clearTimer, setClearTimer] = useState(false);

  const oldCollapseState = localStorage.getItem('navbarCollapseState');
  const [collapse, setCollapse] = useState({
    click: oldCollapseState ? oldCollapseState === 'true' : false,
    hover: false,
    progress: 100,
  });

  useEffect(() => {
    if (!isDesktop) {
      setCollapse({
        click: false,
        hover: false,
        progress: 100,
      });
    }
  }, [isDesktop]);

  const handleToggleCollapse = () => {
    clearInterval(repeater);
    const newCollapseState = !collapse.click;
    setCollapse({ ...collapse, click: newCollapseState });
    localStorage.setItem('navbarCollapseState', String(newCollapseState));
  };

  const handleHoverEnter = () => {
    let callCount = 10;
    const timer = setInterval(function () {
      if (callCount > 0) {
        setCollapse({ ...collapse, progress: callCount * 10 - 50 });
        callCount -= 1;
      } else {
        clearInterval(timer!);
        if (collapse.click) setCollapse({ ...collapse, hover: true });
      }
    }, 100);
    setRepeater(timer);
  };

  const handleHoverLeave = () => {
    setClearTimer(true);
  };

  useEffect(() => {
    if (clearTimer) {
      clearInterval(repeater);
      setCollapse({ ...collapse, hover: false, progress: 100 });
      setClearTimer(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearTimer]);

  return (
    <CollapseDrawerContext.Provider
      value={{
        isCollapse: !collapse.click ? false : collapse.click && !collapse.hover,
        collapseClick: collapse.click,
        collapseHover: collapse.hover,
        hoverProgress: collapse.progress,
        onToggleCollapse: handleToggleCollapse,
        onHoverEnter: handleHoverEnter,
        onHoverLeave: handleHoverLeave,
      }}
    >
      {children}
    </CollapseDrawerContext.Provider>
  );
}

export { CollapseDrawerProvider, CollapseDrawerContext };
