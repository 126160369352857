import { LoadingButton } from '@mui/lab';
import { Autocomplete, Button, Grid, Modal, TextField } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { addUserRoleAllocation, getBuildings, getProjects } from '../../API/API';
import { IRole, IUser } from '../../Types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import useAuth from '../../../../../hooks/useAuth';
import { handleApiError } from '../../../../../utils/HandleApiError';
import { notifyError } from '../../../../../utils/Notification';
import useLocales from '../../../../../hooks/useLocales';
import { IClientInfo, IProject } from '../../../../ProjectManagement/Types/Types';
import { IBuilding } from '../../../../ProjectManagement/Types/Buildings';

interface IAddUserRoleModal {
  modalState: boolean;
  setModalState: Dispatch<SetStateAction<boolean>>;
  selUser: IUser | null;
  assignedUserRoles: IRole[];
  loadUserData: (user: IUser) => void;
}

export default function AddUserRoleModal(props: IAddUserRoleModal) {
  const { t } = useLocales();
  const { logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const [selRole, setSelRole] = useState<IRole | null>(null);
  const [projects, setProjects] = useState<IProject[]>([]);
  const [selProject, setSelProject] = useState<IProject | null>(null);
  const [buildings, setBuildings] = useState<IBuilding[]>([]);
  const [selBuilding, setSelBuilding] = useState<IBuilding | null>(null);
  const roles = useSelector((state: RootState) => state.permissionSlice.roles);

  async function loadProjects() {
    try {
      const res = await getProjects();
      const allProjects: IProject = {
        Id: -1,
        datapointtable: '',
        environment: 0,
        instanceName: '',
        projectname: '--All--',
        timezone: '',
        timezoneId: 0,
        valuetabel: '',
        clientInfo: {} as IClientInfo,
        type: 'project',
        data_input: null,
        dashboard_published: null,
      };
      res.data.push(allProjects);
      setProjects(res.data);
    } catch (error) {
      //TODO
      console.log(error);
    }
  }

  async function loadBuildings(project: IProject | null) {
    if (project) {
      try {
        const res = await getBuildings(project.Id);
        const allBuildings: IBuilding = {
          Id: -1,
          description: '--ALL--',
          project: project.Id,
          code: '',
          street: '',
          hno: '',
          zipcode: '',
          town: '',
          country: '',
          lat: '',
          lon: '',
          label: '',
        };
        res.data.push(allBuildings);
        setBuildings(res.data);
      } catch (error) {
        //TODO
        console.log(error);
      }
    } else {
      setBuildings([]);
    }
  }

  useEffect(() => {
    loadProjects();
  }, []);

  async function onSubmit() {
    try {
      if (props.selUser && selRole && selProject && selBuilding) {
        setLoading(true);
        await addUserRoleAllocation(props.selUser.Id, selRole.Id, selProject.Id, selBuilding.Id);
        setLoading(false);
        props.loadUserData(props.selUser);
        props.setModalState(false);
        setSelProject(null);
        setSelBuilding(null);
        setSelRole(null);
      }
    } catch (error) {
      setLoading(false);
      handleApiError(error, logout);
      notifyError(t('error_adding_permission_role'), 'error_adding_page');
    }
  }

  return (
    <Modal open={props.modalState}>
      <div
        style={{
          background: '#ffffff',
          borderColor: 'grey',
          borderStyle: 'solid',
          borderRadius: 10,
          width: '450px',
          top: '50%',
          left: '50%',
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Grid container direction="column" alignItems="center" style={{ padding: 20, width: '100%' }}>
          <Grid item>{t('add_user_role')}</Grid>
          <Grid item width={'100%'} marginTop={2}>
            <Autocomplete
              disablePortal
              value={selProject}
              options={projects}
              getOptionLabel={(option) => option.projectname}
              renderInput={(params) => <TextField {...params} label={t('project')} />}
              onChange={(event, newValue) => {
                setSelProject(newValue);
                setSelBuilding(null);
                loadBuildings(newValue);
              }}
              fullWidth
            />
          </Grid>
          <Grid item width={'100%'} marginTop={2}>
            <Autocomplete
              disablePortal
              value={selBuilding}
              options={buildings}
              getOptionLabel={(option) => option.description}
              renderInput={(params) => <TextField {...params} label={t('building')} />}
              onChange={(event, newValue) => setSelBuilding(newValue)}
              fullWidth
              disabled={!selProject}
            />
          </Grid>
          <Grid item width={'100%'} marginTop={2}>
            <Autocomplete
              disablePortal
              value={selRole}
              options={roles.filter((role: IRole) => !props.assignedUserRoles.some((ass: IRole) => JSON.stringify(role) === JSON.stringify(ass)))}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label={t('user_role')} />}
              onChange={(event, newValue) => setSelRole(newValue)}
              fullWidth
              disabled={!selProject || !selBuilding}
            />
          </Grid>
          <Grid item style={{ marginTop: 40 }}>
            <Button
              style={{ marginRight: 5 }}
              variant="outlined"
              onClick={() => {
                props.setModalState(false);
              }}
            >
              {t('abort')}
            </Button>
            <LoadingButton variant="outlined" style={{ marginLeft: 5 }} onClick={onSubmit} loading={loading} disabled={!selProject || !selBuilding || !selRole}>
              {t('save')}
            </LoadingButton>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
}
