const lang = {
  //address
  address: 'Address',
  country: 'Country',
  latitude: 'Latitude',
  longitude: 'Longitude',
  edit_address: 'Edit address',
  street: 'Street',
  house_number: 'House number',
  zip_code: 'Zip code',
  city: 'City',

  //device
  device: 'Device',
  device_id: 'Device ID',
  device_type: 'Device type',
  device_type_id: 'Device type ID',
  add_device: 'Add device',
  mac_address: 'MAC address',

  //notify
  error_loading_data: 'The necessary data could not be retrieved from the database!',
  error_loading_data_mds: 'The necessary data could not be retrieved from Mondas!',
  error_sync_metadata: 'Metadata could not be synchronized!',
  error_sync_time_series_data: 'Time series data could not be synchronized!',
  loading_data: 'Data is loaded and prepared. Please wait ...',
  ups_error_loading_data: 'Oops! Something went wrong :(',
  error_updating_data: 'Data could not be edited!',
  error_adding_data: 'Data could not be added!',
  no_empty_fields: 'No empty entries will be accepted! Please fill in all fields!',
  error_set_time_range: 'Please set time range!',
  success_saving_data: 'Data was saved successfully',
  error_saving_data: 'Data could not be saved!',
  fill_all_req_fields: 'Please fill in all required fields!',
  info_restart_device: 'Device is being restarted!',
  info_restart_driver: 'Device is being restarted!',
  error_restart_device: 'Device could not be restarted!',
  error_restart_driver: 'Drivers could not be restarted!',
  error_bacnet_scan_not_started: 'Bacnet scan could not be started!',
  error_bacnet_scan_not_finished: 'Bacnet scan result could not be retrieved. Possibly the scan is not yet completed!',
  success_config_on_device: 'Datapoints have been configured on the device!',
  error_config_on_device: 'Data points could not be configured on the device!',
  error_req_config: 'Configuration could not be requested! Please check your input!',
  error_load_config: 'Configuration could not be loaded! Please start loading manually!',
  error_req_tags: 'No tags could be loaded from the database!',
  error_edit_tag: 'Tag could not be modified!',
  error_adding_tag: 'Tag could not be added to the database!',
  error_adding_project: 'Project could not be added to database!',
  error_editing_project: 'Project could not be edited!',
  success_saving_presets: 'Presets were saved successfully!',
  error_saving_presets: 'Presets could not be transferred to the database! Please try again',
  error_adding_preset: 'Preset could not ne added!',
  error_load_presets: 'Presets could not be loaded',
  error_load_token: 'Token could not be loaded!',
  error_load_token_info: 'Token info could not be loaded!',
  error_adding_token: 'Token could not be added to database!',
  error_load_specific_fields: 'Token specific fields could not be loaded!',
  error_adding_device_type: 'Device type could not be added to database!',
  error_adding_device_: 'Device could not be added to database!',
  error_searching_device: 'No device with this ID could be found!',
  error_adding_device_shortname: 'There is already a device with this abbreviation!',
  error_adding_operation_state: 'Operation state could not be added to database!',
  info_operation_state_duplicate: 'Every value has to be unique! Please compare operation states and value mapping too!',
  error_uploading_image: 'Image could not be uploaded!',
  error_changing_password: 'Password could not be changed!',
  warning_selection_filter: 'Attention! Rows are selected which are not visible due to the set filter!',
  error_rename_preset_node: 'Preset could not be renamed!',
  error_empty_name_field: 'Please assign name!',
  system_already_exists: 'This system does already exist!',
  changes_not_saved: 'Attention! Changes were not saved!',
  error_updating_structure: 'Structure could not be updated!',
  error_not_all_data_add_chirpstack_device: 'Abbreviation", "APP KEY" and "DEV EUI" for Chirpstack must be filled in!',
  error_adding_chirpstack_device: 'Device could not be added to Chirpstack!',
  success_sending_device_to_chirpstack: 'Device has been successfully sent to Chirpstack!',
  error_printer_connection: "No connection to the label printer could be established. Please check if 'Browser Print' software is running!",
  error_polling_token_state: 'No synchronization progress could be loaded!',
  error_adding_permission_action: 'Action could not be added!',
  error_deleting_permission_action: 'Action could not be removed!',
  error_adding_permission_page: 'Page could not be added!',
  error_deleting_permission_page: 'Page could not be removed!',
  error_adding_permission_role: 'User role could not be added!',
  error_deleting_permission_role: 'User role could not be deleted!',
  error_adding_user: 'User could not be added!',
  error_deleting_user: 'User could not be deleted!',
  error_changing_user_state: 'User state could not be changed!',
  error_data_incoming_since: 'Date could not be updated!',
  error_deleting_tag: 'Tag could not be deleted!',
  system_not_up_to_date: 'System structure is not up to date!',
  no_datapoints_to_log: 'There are no datapoints tagged for logging!',
  error_datapoint_already_exists: 'Datapoint already exists!',
  error_deleting_data: 'Data could not be deleted!',
  no_undo_available: 'No further steps can be undone!',
  error_backup_data: 'This step could not be backed up and therefore cannot be undone!',
  error_submit_jour_fixe_data: 'Jour fixe could not be saved!',

  //time
  date_time_format: 'yyyy-MM-dd HH:mm',
  date_format: 'yyyy-MM-dd',
  timezone: 'Timezone',
  data_from: 'data from',
  data_to: 'data to',
  sync_from: 'sync from',
  sync_to: 'sync to',
  last_update: 'Last update',
  update_interval: 'Update interval',
  added_at: 'added at',
  progress: 'progress',
  finished_at: 'finished at',
  data_incoming_since: 'Data incoming since',
  from: 'From',
  to: 'To',
  all_day: 'All day',

  //control
  abort: 'Abort',
  save: 'Save',
  close: 'Close',
  retry: 'Try again!',
  reset_values: 'reset values',
  expert_mode: 'expert mode',
  set_tags: 'set tags',
  set_color: 'set color',
  copy_cell: 'Copy cell value',
  autosize_columns: 'autosize columns',
  tag_menu: 'Tag menu',
  all_columns: 'all columns',
  sync_metadata: 'synchronize metadata',
  show_values: 'Show values',
  update_log: 'update log',
  sync_time_series_data: 'Sync time series data',
  restart_device: 'Restart device',
  restart_driver: 'Restart drivers',
  req_config: 'Request configuration',
  req_config_manual: 'Request configuration manually',
  config_to_device: 'Load configuration to device',
  scan_from: 'Scan from*',
  scan_to: 'Scan to*',
  start_bacnet_scan: 'Start bacnet scan',
  get_scan_result_manual: 'Request result manually',
  conf_datapoints_on_device: 'Configure datapoints on device',
  send_settings_to_database: 'Send settings to database',
  name_in_button: 'Show name in button',
  normalize_needed: 'Normalize needed',
  do_not_set_operation_state: 'Do not set operation states',
  set_operation_state: 'Set operation states',
  set_subscribers: 'Set subscribers',
  capture_photo: 'Capture photo',
  read_text: 'Read text',
  choose_image: 'Choose image',
  scan_area: 'Scan area',
  logout: 'Logout',
  change_password: 'Change password',
  rename: 'Rename',
  display_name_de: 'Display name DE',
  display_name_en: 'Display name EN',
  yes: 'Yes',
  no: 'No',
  delete: 'Delete',
  update_structure: 'Update structure',
  change_system_tree_format: 'Switch structure',
  load: 'Load',
  copy_zpl: 'Copy ZPL',
  send_chirpstack: 'Send to Chirpstack',
  copy: 'copy',
  paste: 'paste',
  update_rule_to_newest_version: 'Update rule to newest version',
  update_function_to_newest_version: 'Update function to newest version',
  update_selectors_to_newest_version: 'Update selector to PROD version',
  update_colormap_to_newest_version: 'Update colormap to PROD version',
  add_rule: 'Add rule to',
  add_rules: 'Add rules to',
  add_function: 'Add function to',
  add_functions: 'Add functions to',
  add_selector: 'Add selector from PROD to ',
  add_selectors: 'Add selectors from PROD to ',
  add_colormaps: 'Add colormaps from PROD to ',
  add_colormap: 'Add colormap from PROD to ',
  remove_backup_from_database: 'Remove Backup',
  remove_rule_from_database: 'Remove backup completely',
  add_preset: 'Add Preset',
  question_german: 'Question in German',
  question_english: 'Question in English',
  save_and_exit: 'Save and Exit',
  save_and_datapoints: 'Save and configure datapoints',
  back: 'Back',
  continue: 'Continue',
  system_successfully_configured: 'System successfully configured!',
  filter_not_tagged: 'Filter untagged datapoints',
  toggle_checklist: 'checklist',
  generate_offer: 'generate offer',
  upgrade_to_project: 'Upgrade to project',
  repeat_code: 'Repeat code',
  print_label: 'Print label',
  show_all_rules: 'All rules',
  show_rules_under_80_percent: 'Rules with performance < 80%',
  move: 'Move element',
  move_here: 'Move item here',
  add_action: 'Add action',
  add_page: 'Add page',
  actions: 'Actions',
  pages: 'Pages',
  user_roles: 'User roles',
  user_role: 'User role',
  role_management: 'Role management',
  actions_and_pages: 'Actions and pages',
  collapse: 'Collapse',
  expand: 'Expand',
  choose_ede_file: 'Choose EDE-File',
  csv_columns: 'CSV columns',
  order_number: 'Order number',
  moment: 'Moment',
  title: 'Title',
  resUsers: 'Responsible',
  search: 'Search',
  message: 'Message',
  address_to: 'Address to ...',
  undo: 'undo',
  upload: 'Upload',
  logging: 'Logging',
  no_logging: 'No logging',
  get_2_factor_qr: 'Two factor QR code',
  list: 'List',
  manual: 'Manual',
  duplicate: 'Duplicate',

  //menu names
  systems: 'Systems',
  datapoint_manager: 'Datapoint manager',
  projects: 'Projects',
  building: 'Building',
  buildings: 'Buildings',
  devices: 'Devices',
  device_types: 'Device types',
  info: 'Info',
  settings: 'Settings',
  config: 'Configuration',
  datapoints: 'Datapoints',
  restart: 'Restart',
  operation_states: 'Operation states',
  system_configurator: 'System configurator',
  rules_version_management: 'Rules version management',
  offer_config: 'Offer configuration',
  raw_system_editor: 'System editor',
  permission_manager: 'Permission manager',

  //table headers
  dp_tablename: 'dp_tablename',
  val_tablename: 'val_tablename',
  object_instance: 'Object instance',
  object_type: 'Object type',
  dp_key: 'dp-key',
  unit: 'Unit',
  units: 'Units',
  description: 'Description',
  designation: 'Designation',
  token_type_id: 'Token type ID',
  token_type_name: 'Token type name',
  short: 'Abbreviation',
  type: 'Type',
  value: 'Value',
  name: 'Name',
  failure_message: 'failure message',
  building_id: 'Building ID',
  device_object_instance: 'Device object instance',
  object_name: 'Object name',
  cov_increment: 'COV increment',
  maximum: 'Maximum value',
  minimum: 'Minimum value',
  bacnet_object_type: 'Bacnet object type',
  operation_state: 'Operation state',
  normalized: 'Normalized',
  priority: 'Priority',
  operator: 'Operator',
  text: 'Text',
  subscriber: 'Subscriber',
  subscribers: 'Subscribers',
  no_subscribers: 'No Subscribers',
  active: 'Active',
  inactive: 'Inactive',
  instance: 'Instance',
  lastValue: 'Last value',
  value_progression: 'Value progression',
  category: 'Category',
  upToDate: 'Up to date',
  question: 'Question',
  label: 'Label',
  initial_costs: 'Initial costs',
  further_costs: 'Functionally identical systems',
  count: 'count',
  favorite: 'Favorite',
  disabled: 'Disabled',
  files: 'Files',
  your_tasks: 'Your tasks',
  notes: 'Notes',
  lastValueTimestamp: 'Last value timestamp',
  dp_add_datetime: 'Datapoint added',

  //headers
  add_project: 'Add project/offer',
  edit_project: 'Edit project',
  edit_system: 'Edit system',
  edit: 'Edit',
  add_building: 'Add building',
  specific: 'Specific',
  metadata_log: 'Meta data log',
  time_series_log: 'Time series log',
  adding_tag_to: 'Adding tag to:',
  add_token: 'Add token',
  add_device_type: 'Add device type',
  add_operation_state: 'Add operation state',
  value_mapping: 'Value mapping',
  user_fields: 'User fields',
  color: 'Color',
  processed_files: 'Processed files',
  values: 'Values',
  stored: 'stored',
  data_volume: 'Data volume',
  csvs_in_queues: 'CSVs in Queue',
  devices_offline: 'Devices offline',
  last_24_h: 'Last 24 hours',
  assigned: 'Assigned',
  receivers: 'Receivers',
  reports: 'Reports',
  client: 'Client',
  catalog: 'Catalog',
  service: 'Service',
  add_user_role: 'Add user role',
  add_user: 'Add user',
  individually_unlocked_actions: 'Individually unlocked actions',
  individually_unlocked_pages: 'Individually unlocked pages',
  add_datapoint: 'Add Datapoint',

  //others
  project: 'Project',
  project_id: 'Project ID',
  adding: 'adding',
  removing: 'removing',
  driver: 'Driver',
  req_result_auto: 'Result will me requested automatically in',
  folder: 'Folder',
  property: 'Property',
  dashed: 'Dashed',
  dotted: 'Dotted',
  solid: 'Solid',
  corresponds_to: 'corresponds to',
  number_filtered_rows: 'Number filtered rows',
  number_selected_rows: 'Number selected rows',
  enter_valid_mail: 'Please enter a valid mail address',
  enter_mail: 'Please enter a mail address',
  enter_password: 'Please enter a password',
  mail_or_password_wrong: 'Mail or password incorrect!',
  two_factor_token_wrong: 'Two factor token is wrong!',
  password: 'Password',
  mail: 'Mail address',
  firstname: 'Firstname',
  lastname: 'Lastname',
  unknown_error: 'An unknown error has occurred!',
  password_old: 'Old password',
  password_new: 'New password',
  old_pass_not_ok: 'Password does not match the previous password!',
  pass_length_error: 'Password must be at least 8 characters long!',
  pass_digit_error: 'Password must contain at least one number!',
  pass_upper_lower_case: 'Password must contain upper and lower case letters!',
  enter_login_data: 'Please enter login data',
  technical_monitoring: 'TÜV SÜD technical monitoring',
  default_value: 'Default value',
  unsaved_data: 'Attention. Changes were not saved. Are you sure you want to leave the page?',
  selected_value_default: 'Select default value by selecting the row',
  local: 'Local',
  sync_full: 'All data points are synchronized with the timeseries data of the selected time range',
  sync_only_empty: 'Only those data points are synchronized for which no timeseries data are yet available in Mondas at the start time (“Data from“)',
  loading_last_values: 'Values will be loaded',
  user: 'User',
  ede_takes_time: 'Processing the EDE file may take some time! Please wait ...',
  todo_done_by: 'Done by <<FIRSTNAME>> <<LASTNAME>> on <<DATE>>!',
  or: 'or',
  data_input: 'Data input',
  dashboard_published: 'Dashboard published',
  german: 'German',
  english: 'English',

  //page title
  device_list: 'Device list',
  device_config: 'Device config',
  project_datapoint_manager: 'Project datapoint manager',
  project_systems: 'Project systems',
  project_buildings: 'Project buildings',
  project_token: 'Project token',
  project_devices: 'Project devices',
  project_info: 'Project info',
  permission_denied: 'Permission Denied',
  no_permission: 'You do not have permission to access this page!',
  optical_character_recognition: 'Optical character recognition',
  are_you_sure: 'Are you sure?',
  auto_interim_reports: 'Auto interim report',
  manual_reports: 'Manual interim report',
  final_report: 'Final report',
  mds_rules: 'Mondas rules',
  mds_functions: 'Mondas functions',
  mds_selectors: 'Mondas selectors',
  mds_colormaps: 'Mondas colormaps',
  rules: 'Rules',
  rule: 'Rule',
  functions: 'Functions',
  function: 'Function',
  offers: 'Offers',
  offer: 'Offer',
  confirm_deleting: 'Confirm deleting of',
  permission_management: 'Permission management',
  calendar: 'Calendar',
  change_device_project: 'Change device assignment',
  selectors: 'Selectors',
  selector: 'Selector',

  //tooltips
  delete_preset_button: 'Delete preset button',
  duplicate_preset_button: 'Duplicate preset button',
  add_preset_button: 'Add preset button',
  set_preset_image: 'Set preset image',

  //notifications
  notifications: 'Notifications',
  mark_all_as_read: 'Mark all notifications as read',
  new: 'New notifications',
  before_that: 'Notifications read',
  no_notifications: 'No notifications available',
  delete_all: 'Delete all notifications',
  new_message: 'New Message!',
  new_message_from: 'You have a new message from',
  in_todo: 'in task',
  of_project: 'of project',
  new_todo: 'New task!',
  new_todo_from: 'You have been assigned to a new task by',
};

export default lang;
